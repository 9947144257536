import React, { useState, useEffect } from "react"
import Layout from "../components/layout"

import SEO from "@accrosoft-ltd/accropress-websites-components/dist/esm/seo"
import WigLoader from "@accrosoft-ltd/accropress-websites-components/dist/esm/wigLoader"

import BasicSlider from "../components/BasicSlider"

import config from "../../config.json"

require("es6-promise").polyfill()

export default function HomepagePagePage({
  pageContext: {
    integratorData,
    contentMetaData,
    contentDataData,
    navigationData,
    navResultData,
    entireLocationsList,
    customFieldsJSON,
  },
  props,
}) {
  const [vacancyLoading, setVacancyLoading] = useState(true)

  return (
    <Layout {...props}>
      <SEO
        navResultData={navResultData}
        contentDataData={contentDataData}
        integratorData={integratorData}
        contentMetaData={contentMetaData}
      />

      <div className="bannerWrapper home">
        <BasicSlider
          onBeforeChangeFunction={(type, previous, next) => {
            return null
          }}
          height="550px"
          min-height="550px"
          shouldDisplayButtons={false}
          pauseOnHover={false}
          pauseOnFocus={false}
          adaptiveHeight={true}
          onBeforeChangeFunction={(type, next) => {
            return null
          }}
          slides={contentDataData[0].children[2].children}
          OverlayContainer={
            <div className="bannerTextWrapper">
              <div className="bannerText">
                <div className="bannerContent">
                  <h1>{contentDataData[0].children[0].value}</h1>
                  <WigLoader
                    weducWebsitesID={config.weducWebsitesID}
                    weducContainer={config.weducContainerID}
                    weducEntity={config.weducEntityID}
                    contentDataData={contentDataData}
                    contentMetaData={contentMetaData}
                    contentDataArray="0"
                    contentDataChild="1"
                    AccroPressProdEndpoint={config.AccroPressProdEndpoint}
                    AccroPressLocalEndpoint={config.AccroPressLocalEndpoint}
                    integratorID={config.integratorID}
                    integratorAccessToken={config.integratorAccessToken}
                    imageFormatting={
                      process.env.IMAGE_FORMATTING_SECRET ||
                      process.env.GATSBY_IMAGE_FORMATTING_SECRET
                    }
                  />
                </div>
              </div>
            </div>
          }
        />
      </div>

      <div className="main-page-wrapper loaded">
        <div className="banner home-banner"></div>
      </div>

      <div className="container">
        <div className="contentWrapper">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="image">
                <img src={contentDataData[0].children[3].value} />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="blockColour yellow">
                <WigLoader
                  weducWebsitesID={config.weducWebsitesID}
                  weducContainer={config.weducContainerID}
                  weducEntity={config.weducEntityID}
                  contentDataData={contentDataData}
                  contentMetaData={contentMetaData}
                  contentDataArray="0"
                  contentDataChild="4"
                  AccroPressProdEndpoint={config.AccroPressProdEndpoint}
                  AccroPressLocalEndpoint={config.AccroPressLocalEndpoint}
                  integratorID={config.integratorID}
                  integratorAccessToken={config.integratorAccessToken}
                  imageFormatting={
                    process.env.IMAGE_FORMATTING_SECRET ||
                    process.env.GATSBY_IMAGE_FORMATTING_SECRET
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="contentWrapper pink">
        <div className="container">
          <div className="col-12 col-md-12">
            <div className="row">
              <div className="blockColour">
                <WigLoader
                  weducWebsitesID={config.weducWebsitesID}
                  weducContainer={config.weducContainerID}
                  weducEntity={config.weducEntityID}
                  contentDataData={contentDataData}
                  contentMetaData={contentMetaData}
                  contentDataArray="0"
                  contentDataChild="5"
                  AccroPressProdEndpoint={config.AccroPressProdEndpoint}
                  AccroPressLocalEndpoint={config.AccroPressLocalEndpoint}
                  integratorID={config.integratorID}
                  integratorAccessToken={config.integratorAccessToken}
                  imageFormatting={
                    process.env.IMAGE_FORMATTING_SECRET ||
                    process.env.GATSBY_IMAGE_FORMATTING_SECRET
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="contentWrapper">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="blockColour yellow second">
                <WigLoader
                  weducWebsitesID={config.weducWebsitesID}
                  weducContainer={config.weducContainerID}
                  weducEntity={config.weducEntityID}
                  contentDataData={contentDataData}
                  contentMetaData={contentMetaData}
                  contentDataArray="0"
                  contentDataChild="6"
                  AccroPressProdEndpoint={config.AccroPressProdEndpoint}
                  AccroPressLocalEndpoint={config.AccroPressLocalEndpoint}
                  integratorID={config.integratorID}
                  integratorAccessToken={config.integratorAccessToken}
                  imageFormatting={
                    process.env.IMAGE_FORMATTING_SECRET ||
                    process.env.GATSBY_IMAGE_FORMATTING_SECRET
                  }
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="image">
                <img src={contentDataData[0].children[7].value} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
